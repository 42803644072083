import { useEffect } from 'react'
import { navigate } from 'gatsby'
import isInBrowser from '@helpers/is-in-browser'

const NotFoundPage = () => {
  useEffect(() => {
    if (isInBrowser) {
      navigate('/')
      window.location.href = '/'
    } else {
      navigate('/')
    }
  }, [])
  return null
}

export default NotFoundPage
